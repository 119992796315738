(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("./foundation.accordionMenu"), require("./foundation.core"), require("./foundation.drilldown"), require("./foundation.dropdownMenu"), require("./foundation.util.mediaQuery"), require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define(["./foundation.accordionMenu", "./foundation.core", "./foundation.drilldown", "./foundation.dropdownMenu", "./foundation.util.mediaQuery", "jquery"], factory);
	else if(typeof exports === 'object')
		exports["foundation.responsiveMenu"] = factory(require("./foundation.accordionMenu"), require("./foundation.core"), require("./foundation.drilldown"), require("./foundation.dropdownMenu"), require("./foundation.util.mediaQuery"), require("jquery"));
	else
		root["__FOUNDATION_EXTERNAL__"] = root["__FOUNDATION_EXTERNAL__"] || {}, root["__FOUNDATION_EXTERNAL__"]["foundation.responsiveMenu"] = factory(root["__FOUNDATION_EXTERNAL__"]["foundation.accordionMenu"], root["__FOUNDATION_EXTERNAL__"]["foundation.core"], root["__FOUNDATION_EXTERNAL__"]["foundation.drilldown"], root["__FOUNDATION_EXTERNAL__"]["foundation.dropdownMenu"], root["__FOUNDATION_EXTERNAL__"]["foundation.util.mediaQuery"], root["jQuery"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__foundation_accordionMenu__, __WEBPACK_EXTERNAL_MODULE__foundation_core__, __WEBPACK_EXTERNAL_MODULE__foundation_drilldown__, __WEBPACK_EXTERNAL_MODULE__foundation_dropdownMenu__, __WEBPACK_EXTERNAL_MODULE__foundation_util_mediaQuery__, __WEBPACK_EXTERNAL_MODULE_jquery__) {
return 